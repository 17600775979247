$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
.OfferSection {

  @media screen and (min-width: 720px) {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .accordionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 600px;
    margin-left: 12vw;

    @media screen and (min-width: 1921px) {
      margin-left: 15vw;
    }

    @media screen and (max-width: 1500px) {
      max-width: 500px;
    }

    @media screen and (max-width: 1200px) {
      max-width: 450px;
      margin-left: 15vw;
    }

    @media screen and (max-width: 1024px) {
      max-width: calc(100% - 144px);
      margin: 0 72px;
    }

    @media screen and (max-width: 600px) {
      max-width: calc(100% - 64px);
      margin: 0 32px;
    }
    @media screen and (max-width: 480px) {
      max-width: calc(100% - 48px);
      margin: 0 24px;
    }
  }

  .accordionItem {

    @media screen and (max-width: 1024px) {
      gap: 32px;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-bottom: 32px;
    }

    @media screen and (max-width: 600px) {
      align-items: flex-start;
      gap: 24px;
    }
  }

  h1 {
    color: #281B24;
    border-bottom: 1px solid  #211b19;
    width: 100%;
    margin-bottom: 40px;
  }

  h2 {
    color: #F78C50;
    margin-bottom: 16px;

    @media screen and (max-width: 1440px) {
      margin-bottom: 8px;
    }
  }

  h3 {
    text-align: left;
    color: #281B24;
  }

  a {
    margin: 0 auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 96px;
    gap: 10px;

    width: 287px;
    height: 48px;

    background: #F9AC80;
    border-radius: 8px;

  }

  p {
    height: 8.8889vh; //96px;
    color: #281B24;

    @media screen and (min-width: 1921px) {
      margin-bottom: 1vw;
    }
    @media screen and (max-width: 1500px) {
      height: 10vh;
    }
    @media screen and (max-width: 1200px) {
      height: auto;
    }
  }
}
